import './App.css';
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import DownSVG from "./assets/svgs/down.svg"
import GuavaSVG from "./assets/svgs/guava.svg"
import Guava2SVG from "./assets/svgs/guava-2.svg"
import Guava3SVG from "./assets/svgs/guava-3.svg"

function MotionAnimated({ Component, delay, children}) {
  const control = useAnimation()
  const [ref, inView] = useInView()

  const variant = {
    visible: { opacity: 1, transition: { opacity: { delay: delay}}},
    hidden: { opacity: 0, transition: { opacity: { delay: delay}}},
  }

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Component
      ref={ref}
      variants={variant}
      initial="hidden"
      animate={control}>
      {children}
    </Component>
  )
}

function Home() {
  const secondPagePef = useRef(null)
  const executeScroll = () => secondPagePef.current.scrollIntoView() 


  return (
    <>
    <div className="App">
      <section>
        <div className="mid">
          <MotionAnimated Component={motion.h3} delay={0.2}>
            Victor Peleteiro
          </MotionAnimated>
          <MotionAnimated Component={motion.p} delay={0.5}>Product Designer há 6 anos, sendo Design Lead nos últimos 3. Trabalhou em startups e em studios de desenvolvimento, sempre buscando colaborar com o squad do projeto, fazendo pesquisas e validações, além do desenho de telas e experiências, conectando a visão de negócios e criatividade em produtos digitais.</MotionAnimated>
        </div>
        <img src={DownSVG} className="down-icon" onClick={executeScroll}/>
      </section>
    </div>
    <div className="App" ref={secondPagePef}>
    <section className="center-horizontal">
        <MotionAnimated Component={motion.h5} delay={0.2}>UX CASE</MotionAnimated>
        <MotionAnimated Component={motion.h1} delay={0.5}>Guava</MotionAnimated>
        <MotionAnimated Component={motion.p} delay={0.5}>A Guava é um aplicativo de descontos em restaurantes. Fundada em 2015, soma mais de 43k de usuários, e já movimentou 13 milhões em incrementos financeiros aos estabelecimentos parceiros, além dos mais de 7 milhões de economia aos usuários. Ela se baseia na técnica Yield Management, ou seja, ajuda o restaurante a ter menos ociosidade. É a mesma tática do setor hoteleiro e de aviação.</MotionAnimated>
        <MotionAnimated Component={motion.p} delay={0.5}>Para o ano de 2022, A Guava resolveu fazer grandes mudanças, tanto no modelo de negócio quanto no app. Primeiramente, o modelo de negócio que era de resbonsabilidade do restaurante pagar por pessoa sentada, agora é um modelo de recorrência e quem paga são os usuários.</MotionAnimated>
        <MotionAnimated Component={motion.p} delay={0.5}>Devido a quantidade de alterações importantes, revolvemos fazer uma pesquisa com os assinantes, outra com os usuários e por fim, com os restaurantes.</MotionAnimated>
        <MotionAnimated Component={motion.p} delay={0.5}>Através dos insights das pesquisas, a maior problemática percebida junto com os restaurantes era a forma como o assinante Guava validava o desconto. Criamos 3 soluções para resolver o problema reportado, ficando com a última tela abaixo,</MotionAnimated>
    </section>
    <section className="center-horizontal  gray">
      <MotionAnimated Component={motion.div} delay={0.5}>
        <img src={GuavaSVG} alt="Guava"/>
      </MotionAnimated>
    </section>
    <section className="center-horizontal">
      <MotionAnimated Component={motion.p} delay={0.5}>Da parte dos assinantes, ficou apontado que a maioria tinha dificuldade de separar rapidamente as Reservas e Go's (check-in). Para isso, fizemos uma brincadeira para essa primeira fase, que foi separado em abas e cores, a Reserva e o Go, tornando fácil entender qual modelo de benefício o estabelecimento oferece.</MotionAnimated>
    </section>
    <section className="center-horizontal  gray">
      <MotionAnimated Component={motion.div} delay={0.5}>
        <img src={Guava2SVG} alt="Guava"/>
      </MotionAnimated>
    </section>
    <section className="center-horizontal">
      <MotionAnimated Component={motion.p} delay={0.5}>E por último, faltava para o usuário achar o preço da assinatura e uma copy explicando o preço da assinatura seus benefícios.</MotionAnimated>
    </section>
    <section className="center-horizontal  gray">
      <MotionAnimated Component={motion.div} delay={0.5}>
        <img src={Guava3SVG} alt="Guava"/>
      </MotionAnimated>
    </section>
  </div>
  </>
  );
}

export default Home;
